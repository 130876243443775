<template>
    <div class="admin-container" v-if="!loading">
        <h1>Gestion des réponses</h1>
        <h2 v-if="info">{{ `${info.number} ${info.name}`}}</h2>
        <div class="back-bar">
            <router-link to="/speaker/webinar-list" class="back-btn"></router-link>
        </div>
        <template v-if="questionsList.length === 0">
            <div class="error">
                <p>Aucune question pour ce webinar</p>
            </div>
        </template>
        <template v-else>
            <div class="row">
                <div class="video-col">
                    <div class="video-container">
                        <video width="600" controls ref="video">
                            <source :src="`${$store.state.URL}videos/${info.video_link}`" type="video/mp4">
                        </video>
                    </div>
                </div>
                <div class="questions-col">
                    <div class="questions-container" >
                        <div class="user-item" v-for="(user, idz) in users" :key="idz">
                            <div class="user-name">Liste des questions de <strong>{{`${user.firstname} ${user.lastname}`}}</strong></div>
                            <SpeakerForm
                                :user = user
                                :questions="questionsList.filter(e => e.user_id == user.user_id)"
                                v-on:playVideo="playVideo"
                                v-on:getData="getData">
                            </SpeakerForm>
                        </div>                  
                    </div>
                </div>
            </div>
        </template>
        <NotifError :errorTxt="errorTxt"  v-if="errorTxt" v-on:closeModal="errorTxt = null"></NotifError>

    </div>
</template>


<script>
import axios from 'axios'
import store from './../../store'
import NotifError from './../../components/NotifError'
import SpeakerForm from './../../components/speakerForm'

export default {
    name: 'SpeakerWebinarAnswers',
    data()  {
        return {
            info : null,
            questionsList :  [],
            errorTxt : null,
            users : [],
            loading : true,

        }
    },
    components : {
        NotifError, SpeakerForm
    },
    methods : {
        getData() {
            let vm = this
            this.errorTxt = null
            store.state.routerHistory = null

            axios
                .get(store.getters.getURL + "speakers/getQuestions.php?token=" + store.state.JWT_TOKEN + "&id=" + this.$route.params.id)
                .then(function (response) {
                    vm.loading = false
                    if(response.data.state == 'success') {
                        vm.info = response.data.info
                        vm.questionsList = response.data.questions
                        vm.users = response.data.user

                    }else {
                        if(response.data.authError) {
                            store.commit('logout')
                            vm.$router.push('/login')
                        }else {
                            vm.errorTxt = 'An error has occured: ' + response.data.retour
                        }
                    }
                })
                .catch((err) => {
                    this.errorTxt = 'An error has occured: ' +  err
                });
        },
        playVideo(time) {
            const getTime = time.split(':')
            const currentTime = parseInt(getTime[0]) * 60 + parseInt(getTime[1])
            this.$refs.video.play()
            this.$refs.video.pause()
            console.log(currentTime)
            this.$refs.video.currentTime = currentTime
        }
    },
    mounted() {
        this.getData()
    },
}
</script>

<style lang="scss" scoped>
.questions-container {
    width:100%;
    max-width: 1400px;
    margin:0 auto 40px auto;
    padding-bottom:40px;
}

.user-item {
    width:calc(100% - 80px);
    padding:40px;
}

.user-name {
    color:$mainColor;
    text-align: left;
     margin:0 5px;
}



.video-col, .questions-col {
    width:50%;
    position: relative;
}

.video-container {
    position: fixed;
    right:50%;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.user-item:nth-child(odd) {
    background: #f8f8f8;
}

</style>