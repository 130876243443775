<template>
    <div class="notif-error">
        <div class="notif-close" @click="closeModal"></div>
        <p class="notif-text" v-html="errorTxt"></p>
    </div>
</template>

<script>
export default {
    name : 'NotifError',
    props:['errorTxt'],
    methods : {
        closeModal() {
            this.$emit('closeModal')
        }
    }
}
</script>

<style lang="scss" scoped>
    .notif-error {
        position: fixed;
        z-index: 99;
        top:0;
        left:0;
        right:0;
        background:$error;
        box-shadow:0px 5px 5px 0px rgba(0,0,0,0.25) ;
    }

    .notif-close {
        position:absolute;
        top:5px;
        right:15px;
        background: url('./../assets/images/icon-close.png') ;
        background-position: center;
        background-size: 15px;
        height:15px;
        width:15px;
        background-repeat: no-repeat;
        cursor: pointer;
    }

    .notif-text {
        text-align: center;
        font-size:15px;
        color:#fff;
        margin:20px;
    }



</style>