<template>
    <div class="speaker-form">
        <form  @submit.prevent="onSubmit" ref="form">
            <input type="hidden" name="email" :value="user.email" />
            <div v-for="(item, idx) in questions" :key="idx" class="questions-item">
                <label>Timing correspondant de la vidéo : <strong>{{`${item.webinar_video_question_current_time}`}}</strong><br />{{`${item.webinar_video_question_question}`}}</label>
                <template v-if="item.webinar_video_question_answer">
                    <div class="answer">{{item.webinar_video_question_answer}}</div>
                </template>
                <template v-else>
                    <textarea :value="item.webinar_video_question_answer" rows="5" maxlength="500" required @click="playVideo(item.webinar_video_question_current_time)" :name="`answer[${item.webinar_video_question_id}]`"></textarea>
                </template>
            </div>
            <div class="actions-bar">
                <input v-if="!loadingForm && !success && !displayBtn" type="submit" class="btn-form" value="Envoyer mes réponses à ce participant" />
                <Loader v-if="loadingForm"></Loader>
            </div>
            <div class="form-failed" v-if="errorForm" v-html="errorForm"></div>
            <div class="form-succes" v-if="success">Vos réponses ont bien été enregistrées.</div>
        </form>
    </div>
</template>

<script>
import Loader from './../components/Loader'
import axios from 'axios'
import store from './../store'

export default {
    name:"speakerForm",
    props : ['questions', 'user'],
    components : {
        Loader
    },
    data() {
        return {            
            errorForm : null,
            loadingForm : false,
            success : false
        }
    },
    methods : {
        playVideo(item) {
            this.$emit('playVideo', item)
        },
        onSubmit() {
            let vm = this
            this.loadingForm = true
            let form = this.$refs.form
            let formData = new FormData(form);
            formData.append('token', store.state.JWT_TOKEN)
            formData.append('webinar_id', this.$route.params.id)

            this.errorForm = null

            axios
                .post(store.getters.getURL + "speakers/addAnswers.php", formData)
                .then(function (response) {
                    vm.loadingForm = false
                    if(response.data.state == 'success') {
                        vm.$emit('getData')
                        vm.succes = true
                    }else {
                        if(response.data.authError) {
                            store.commit('logout')
                            vm.$router.push('/login')
                        }else {
                            vm.errorForm = 'An error has occured: ' + response.data.retour
                        }
                    }
                })
                .catch((err) => {
                    this.errorForm = 'An error has occured: ' +  err
                });
        },
    },
    computed : {
        displayBtn() {
            let counter = 0;
            if(this.questions != undefined) {
                this.questions.forEach(function(e) {
                            if(!e.webinar_video_question_answer || e.webinar_video_question_answer == null || e.webinar_video_question_answer.length == 0) {
                                counter++
                                console.log(e.webinar_video_question_id + '++')
                            }
                });
                if(counter > 0) {
                    return  false
                }else {
                    return true
                }
            }else {
                return true
            }
         
        }
 
    }   
}
</script>

<style lang="scss" scoped>
    .questions-item {
        margin:20px 0;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .questions-item label {
        color:$secondaryColor;
        margin:0 5px 10px 5px;
    }

    .questions-item textarea {
        color:$secondaryColor;
        font-size:13px;
        padding:20px;
        background:#e6e6e6;
        border:1px solid #e6e6e6;
    }

    .answer {
        margin:0 5px;
        color:#9e9e9e;
        font-style: italic;
    }

    .btn-form  {
        width:auto;
    }
</style>